
import Vue from 'vue'
import Component from "vue-class-component";
import StickyBackgroundComponent from "@/components/pages/StickyBackgroundComponent.vue";

@Component({
  components: {
    StickyBackgroundComponent
  }
})
export default class Doelgroep extends Vue {

}

import axios from 'axios';

// apply interceptor on response
axios.interceptors.response.use(
    response => response,
    error => {
      // Possible extensions: (https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/)
      // - error.response exists > your server responded with a 4xx/5xx error
      // - error.request exists > happens when the browser was able to make a request, but for some reason, it didn't see a response
      // - error.response & error.request don't exist > means it's not an axios error and theres likely something else wrong in your app. The error message + stack trace should help you figure out where its coming from.

      const notificationText =
          error.response?.config.notification || 'general.error';
      console.log(notificationText);

      return Promise.reject(error);
    }
);

export default axios;


import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({
  components: {}
})
export default class ContactDialog extends Vue {
  @Prop()
  title!: string;
  @Prop()
  options!: ContactOption[];
}

export interface ContactOption {
  icon: string;
  value: string;
  link?: string;
}

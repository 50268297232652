import axios from '@/plugins/axios';

class MailService {
    private BASE_API = process.env.mailApi || `https://bo-website-mail.herokuapp.com/api/mail`

    public sendMail(firstName: string, lastName: string, question: string, email: string, phoneNr: string): Promise<void> {
        return axios.post(
            this.BASE_API,
            {
                firstName, lastName, question, email, phoneNr
            }
        )
    }
}

export const mailService = new MailService();

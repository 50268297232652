
import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({
  components: {}
})
export default class CardContactDialog extends Vue {
  @Prop()
  title!: string;
  @Prop()
  options!: ContactOption[];
  @Prop()
  img!: string;

  clickOption(option: ContactOption) {
    switch (option.type) {
      case "phone":
        window.open(`tel:${option.value}`, '_blank');
        break;
      case "mail":
        window.open(`mailto:${option.value}`, '_blank');
        break;
      case "address":
        window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(option.value)}`, '_blank');
    }
  }

  getSubtitle(option: ContactOption) {
    switch (option.type) {
      case "phone":
        return "GSM";
      case "mail":
        return "Mail";
      case "address":
        return option.value.split(', ')[1];
    }
  }
}

export interface ContactOption {
  icon: string;
  value: string;
  link?: string;
  type: "phone" | "mail" | "address";
}

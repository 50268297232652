
import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({
  components: {}
})
export default class Quote extends Vue {
  @Prop()
  text!: string;

  @Prop()
  author!: string;
}

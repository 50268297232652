
import Vue from 'vue'
import Component from "vue-class-component";
import StickyBackgroundComponent from "@/components/pages/StickyBackgroundComponent.vue";

@Component({
    components: {StickyBackgroundComponent}
})
export default class OverMij extends Vue {
    public items = [
        {
            color: 'primary',
            icon: 'mdi-school',
            timeLabel: "2014 – 2018",
            htmlTitle: `Afgestudeerd`,
            htmlSubtitle: `Bachelor logopedie en audiologie, afstudeerrichting logopedie - Vives Hogeschool Brugge`,
            htmlText: `<p class="text-justify">
            In 2018 ben ik afgestudeerd als trotse logopediste aan de Katholieke hogeschool Vives in Brugge. Tijdens mijn opleiding en diverse
            stages ontdekte ik mijn passie voor het begeleiden van mensen met verworven taal-, spraak- en slikstoornissen.
          </p>`,
        },
        {
            color: 'primary',
            icon: 'mdi-book-information-variant',
            timeLabel: "2020 - 2022",
            htmlTitle: 'Postgraduaat',
            htmlSubtitle: `Postgraduaat neurogene communicatiestoornissen - Artevelde Hogeschool`,
            htmlText: `<p class="text-justify">Mijn interesse is in de loop van de jaren alleen maar gegroeid, waardoor ik in 2020 ben gestart met het tweejarig postgraduaat neurogene communicatiestoornissen aan de Artevelde Hogeschool. De opleiding sterkt me in mijn kennis en ervaring. </p>`
        }
    ];

}

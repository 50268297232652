
import Vue from 'vue'
import Component from "vue-class-component";
import StickyBackgroundComponent from "@/components/pages/StickyBackgroundComponent.vue";
import {mailService} from "@/services/mail-service";

@Component({
  components: {StickyBackgroundComponent}
})
export default class Praktisch extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };
  maxCharacters = 20;
  valid = false;
  submitted = false;
  busy = false;
  firstName = '';
  lastName = '';
  nameRules = [
    (v: string) => !!v || 'Dit is een verplicht veld',
    (v: string) => v.length <= this.maxCharacters || `Je mag maximum ${this.maxCharacters} characters gebruiken`
  ];
  email = '';
  emailRules = [
    (v: string) => !!v || 'E-mail is verplicht',
    (v: string) => /.+@.+/.test(v) || 'E-mail must be valid',
  ];
  question = '';
  questionRules = [
    (v: string) => !!v || 'Je vraag is verplicht',
  ];
  phoneNumber = '';
  phoneNumberRules = []
  maxCharactersPhoneNumber = 15;

  tarifs = {
    headers: [
      {text: "Prestatie", value: "prestatie"},
      {text: "Honorarium", value: "honorarium"},
      {text: "Normaal verzekerd", value: "verzekerd", colspan: 2},
      {text: "Verhoogde tegemoetkoming", value: "tegemoetkoming", colspan: 2},
    ],
    tableData: [
      {
        prestatie: "Aanvangsbilan per halfuur",
        honorarium: "€ 42.75",
        verzekerd: {value: "€ 32.25", cost: "€ 7.5"},
        tegemoetkoming: {value: "€ 39.75", cost: "€ 3"}
      },
      {
        prestatie: "Evolutiebilan",
        honorarium: "€ 60.31",
        verzekerd: {value: "€ 49.11", cost: "€ 11.00"},
        tegemoetkoming: {value: "€ 55.81", cost: "€ 4.5"},
      },
      {
        prestatie: `Individuele zitting (30 minuten) *`,
        honorarium: "€ 36.14",
        verzekerd: {value: "€ 30.64", cost: "€ 5.50"},
        tegemoetkoming: {value: "€ 34.14", cost: "€ 2.00"},
      },
      {
        prestatie: `Individuele zitting (60 minuten)`,
        honorarium: "€ 72.58",
        verzekerd: {value: "€ 61.58", cost: "€ 11.00"},
        tegemoetkoming: {value: "€ 68.08", cost: "€ 4.50"},
      }
    ]
  }

  mounted(): void {
    if (this.$route?.hash) {
      const el = document.querySelector(this.$route.hash);
      el && el.scrollIntoView()
    }
  }

  submit(): void {
    this.busy = true;
    console.log("submit");
    mailService.sendMail(
        this.firstName,
        this.lastName,
        this.question,
        this.email,
        this.phoneNumber
    ).then(() => {
      this.$refs.form.reset()
      this.submitted = true;
    }).catch(() => {
      alert("something went wrong while sending form");
    }).finally(() => {
      this.busy = false;
    })

  }
}

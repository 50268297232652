
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";

@Component({
  components: {}
})
export default class StickyBackgroundComponent extends Vue {
  @Prop()
  title!: string;

  @Prop()
  text!: string;

  @Prop()
  backgroundImageUrl?: string;

  getBackgroundImageUrl(): string {
    if (!this.backgroundImageUrl) {
      return '@/assets/Welkom.webp/';
    }
    return this.backgroundImageUrl;
  }

}

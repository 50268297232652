import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            // light: {
            //     primary: '#1976D2',
            //     secondary: '#424242',
            //     accent: '#82B1FF',
            //     error: '#FF5252',
            //     info: '#2196F3',
            //     success: '#4CAF50',
            //     warning: '#FFC107',
            //     light: '#c8daff'
            // },
            light: {
                primary: '#A1DAC3',
                secondary: '#217AAE',
                accent: '#74C4BE',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                light: '#c8daff',
                transparant: '#000000'
            },
        },
    },
    icons: {
        iconfont: 'mdiSvg',
        values: {
            facebook: 'mdi-facebook'
        }
    }
});


import Vue from 'vue'
import Component from "vue-class-component";
import {mdiFacebook} from "@mdi/js";
import CardContactDialog, {ContactOption} from "@/components/CardContactDialog.vue";

@Component({
  components: {CardContactDialog}
})
export default class Main extends Vue {
  public drawer = false;
  public group = -1;
  public svgPath = mdiFacebook

  public nav = [
    {to: "/", label: 'Welkom'},
    {to: "over-mij", label: 'Over Ons'},
    {to: "doelgroep", label: 'Doelgroep'},
    {to: "praktisch", label: 'Praktisch'}
  ]

  img = require('@/assets/Over mij - foto.webp')
  options: ContactOption[] = [{
    link: 'mailto:info@bo-neurologopedie.be',
    icon: 'mdi-email',
    value: 'info@bo-neurologopedie.be',
    type: 'mail'
  }, {
    link: 'tel:+32 474 50 03 93',
    icon: 'mdi-phone',
    value: '+32 474 50 03 93',
    type: 'phone'
  }, {
    type: 'address',
    icon: 'mdi-map-marker',
    value: 'Waterstraat 49, 8730 Beernem',
    link: ''
  }]
}

import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from "@/components/pages/Home.vue";
import OverMij from "@/components/pages/OverOns.vue";
import Praktisch from "@/components/pages/Praktisch.vue";
import Doelgroep from "@/components/pages/Doelgroep.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/over-mij',
    name: 'Over Ons',
    component: OverMij
  },
  {
    path: '/doelgroep',
    name: 'Doelgroep',
    component: Doelgroep
  },
  {
    path: '/praktisch',
    name: 'Praktisch',
    component: Praktisch
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

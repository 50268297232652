
import Vue from 'vue';
import Component from "vue-class-component";
import Main from "@/components/Main.vue";

@Component({
  components: {Main}
})
export default class App extends Vue {
}


import Vue from 'vue'
import Component from "vue-class-component";
import Quote from "@/components/pages/Quote.vue";
import ContactDialog from "@/components/ContactDialog.vue";

@Component({
  components: {ContactDialog, Quote}
})
export default class Home extends Vue {

  quotes = [
    {
      text: `Bo streeft naar communicatie en contact met andere mensen. Ze zoekt naar alternatieve manieren om de communicatie te ondersteunen.
      Oefeningen worden helder en duidelijk verwoord. De sociale omgeving wordt nauw betrokken.
      Ik ben erg tevreden over de logopedische begeleiding. `,

      author: ''
    }, {
      text: `Bo is creatief in haar oefeningen en heeft oog voor de nieuwste technologie.
      Ze is mee met de laatste stand van zaken. Ik heb vertrouwen in haar en ben tevreden van de begeleiding aan huis.`,

      author: ''
    },
  ]
}
